<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-icon v-bind="{ ...attrs, ...$attrs }" v-on="on">
        {{ icon }}
      </v-icon>
    </template>
    <span><slot></slot></span>
  </v-tooltip>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    icon: {
      type: String,
      required: true,
    },
  },
}
</script>
