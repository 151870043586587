<template>
  <div class="wrapper">
    <v-card
      class="py-12 px-7 mx-auto"
      width="320"
      elevation="16"
      v-show="page === 1"
    >
      <v-card-title class="text-h4 justify-center mb-6">{{
        $t("auth.detailsCompletion")
      }}</v-card-title>
      <v-card-subtitle class="text-h6 text-center mb-8">{{
        $t("general.personalInfo")
      }}</v-card-subtitle>
      <validation-observer ref="observer" v-slot="{ invalid }">
        <form @submit.prevent="incrementPage" data-testid="form-1">
          <validation-provider v-slot="{ errors }" name="name" rules="required">
            <v-text-field
              v-model="registrationInfo.name"
              data-testid="name"
              :error-messages="errors"
              :label="$t('general.name')"
              required
            ></v-text-field>
          </validation-provider>

          <validation-provider
            v-slot="{ errors }"
            name="phone"
            rules="required|numeric|phoneNumberIsrael"
          >
            <v-text-field
              v-model="registrationInfo.phone"
              data-testid="phone"
              :error-messages="errors"
              :label="$t('general.phoneNumber')"
              required
            />
          </validation-provider>

          <div class="mx-auto d-flex justify-center mt-12">
            <v-btn
              class="ml-3 white--text"
              type="submit"
              color="primary"
              elevation="3"
              :disabled="invalid"
            >
              {{ $t("userActions.next") }}
            </v-btn>
          </div>
        </form>
      </validation-observer>
    </v-card>
    <v-card
      class="py-12 px-7 mx-auto"
      width="320"
      elevation="16"
      v-show="page === 2"
    >
      <v-card-title class="text-h4 justify-center mb-6">{{
        $t("auth.detailsCompletion")
      }}</v-card-title>
      <v-card-subtitle class="text-h6 text-center mb-8">{{
        $t("auth.confirmDetails")
      }}</v-card-subtitle>
      <form @submit.prevent="submit" data-testid="form-3">
        <v-card-text
          class="text-center mb-5 text-subtitle-1 font-weight-bold"
          >{{ $t("general.personalInfo") }}</v-card-text
        >
        <v-card-text
          ><b>{{ $t("general.name") }}:</b>
          {{ registrationInfo.name }}
        </v-card-text>
        <v-card-text
          ><b>{{ $t("general.phoneNumber") }}:</b>
          {{ registrationInfo.phone }}</v-card-text
        >
        <br />
        <div class="mx-auto d-flex justify-center mt-12">
          <v-btn
            class="ml-3 white--text"
            type="submit"
            color="primary"
            elevation="3"
          >
            {{ $t("auth.detailsConfirmation") }}
          </v-btn>
          <v-btn
            class="mr-3"
            type="button"
            color="primary"
            elevation="3"
            outlined
            @click="decrementPage()"
          >
            {{ $t("userActions.back") }}
          </v-btn>
        </div>
      </form>
    </v-card>
    <modal
      :redirectComponentName="modalRedirectComponentName"
      v-show="popupMsg !== ''"
      @close="popupMsg = ''"
    >
      {{ popupMsg }}
    </modal>
  </div>
</template>
<script>
import store from "../vuex/store"
import debounce from "lodash/debounce"
import { mapActions } from "vuex"
import { ValidationObserver, ValidationProvider } from "vee-validate"
import { ZIP_CODE_VALIDATION_RULE } from "../helpers/constants/constants"
import Modal from "../components/Modal"

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    Modal,
  },
  data() {
    return {
      ZIP_CODE_VALIDATION_RULE,
      modalRedirectComponentName: "",
      slug: null,
      showPass: false,
      page: 1,
      popupMsg: "",
      registrationInfo: {
        name: "",
        phone: "",
      },
    }
  },

  async mounted() {
    let userDetails = await store.dispatch("user/getUserDetails")
    this.slug = userDetails.slug
  },

  methods: {
    ...mapActions("user", ["updateUserDetails"]),
    ...mapActions("coordinator", ["updateProfile"]),
    submit: debounce(
      function () {
        let userDetailsPayload = this.createUserSubmitPayload()
        let profilePayload = this.createProfileSubmitPayload()
        this.postRegistrationData(userDetailsPayload, profilePayload)
      },
      500,
      { leading: true, trailing: false }
    ),

    createUserSubmitPayload() {
      return {
        name: this.registrationInfo.name,
      }
    },

    createProfileSubmitPayload() {
      let profilePayload = new FormData()
      profilePayload.append("phone_number", this.registrationInfo.phone)
      return profilePayload
    },

    async postRegistrationData(userDetailsPayload, profilePayload) {
      try {
        await this.updateProfile({ slug: this.slug, profile: profilePayload })
        await this.updateUserDetails({
          slug: this.slug,
          userDetails: userDetailsPayload,
        })
        this.modalRedirectComponentName = "Profile"
        this.popupMsg = this.$t("general.detailsSuccessfullyUpdated")
      } catch (err) {
        if (
          err.response &&
          err.response.status === 400 &&
          Object.keys(err.response.data).length > 0
        ) {
          this.popupMsg =
            err.response.data[Object.keys(err.response.data)[0]][0]
        } else {
          this.popupMsg = this.$t("errors.genericError")
        }
      }
    },

    incrementPage() {
      this.page += 1
    },

    decrementPage() {
      this.page -= 1
    },
  },
}
</script>

<style lang="scss" scoped>
.wrapper {
  margin-top: 100px;
  margin-bottom: 30px;
}

.v-card__subtitle,
.v-card__text,
.v-card__title {
  padding: 0;
}
</style>
