var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper mx-auto mt-16"},[_c('h1',{staticClass:"mb-5"},[_vm._v(" "+_vm._s(((_vm.$tc("invite.invite", 1)) + " " + (_vm.$t("general.student"))))+" ")]),_c('h2',{staticClass:"pb-12"},[_vm._v(" "+_vm._s(_vm.$t("invite.inviteStudentsToPlatformAndKeepTrackOfTheirStatus"))+" ")]),_c('div',{staticClass:"mx-auto d-flex justify-center mt-10"},[_c('v-card',{staticClass:"mb-15",attrs:{"elevation":"3"}},[_c('v-card-title',[_c('v-text-field',{staticClass:"px-10 mt-5 mb-8",attrs:{"append-icon":"mdi-magnify","label":_vm.$t('userActions.search'),"single-line":"","hide-details":""},on:{"click:append":function($event){_vm.tableProps.options.page = 1
            _vm.getStudents()},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.tableProps.options.page = 1
            _vm.getStudents()}},model:{value:(_vm.searchFilter),callback:function ($$v) {_vm.searchFilter=$$v},expression:"searchFilter"}})],1),_c('v-data-table',_vm._b({attrs:{"show-select":"","multi-sort":""},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"size":"20"},on:{"click":function($event){return _vm.editStudent(item)}}},[_vm._v(" mdi-pencil ")])]}},{key:"item.profile.gender",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(("gender." + (item.profile.gender.toLowerCase()))))+" ")]}}]),model:{value:(_vm.selectedRows),callback:function ($$v) {_vm.selectedRows=$$v},expression:"selectedRows"}},'v-data-table',_vm.tableProps,false,true)),_c('v-card-actions',{staticClass:"grey lighten-5 mt-3"},[_c('v-btn',{class:{ 'abs-center': _vm.$vuetify.breakpoint.smAndUp },attrs:{"text":""},on:{"click":_vm.addStudent}},[_vm._v(" "+_vm._s(((_vm.$tc("invite.invite", 1)) + " " + (_vm.$t("general.student"))))+" ")]),_c('v-spacer'),_c('div',{staticClass:"pl-2"},[_c('v-btn',{attrs:{"text":"","color":"error","disabled":!_vm.selectedRows.length},on:{"click":_vm.handleDeleteRequest}},[_vm._v(" "+_vm._s(((_vm.$tc("userActions.remove", 1)) + " " + (_vm.$t("general.student"))))+" ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.triggerCSVUpload}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-file-upload")])],1)]}}])},[_c('span',{staticClass:"px-3"},[_vm._v(_vm._s(_vm.$t("userActions.import"))+" CSV")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.exportCSV(_vm.tableProps.items)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-file-download-outline")])],1)]}}])},[_c('span',{staticClass:"px-3"},[_vm._v(_vm._s(_vm.$t("userActions.export")))])])],1)],1)],1),_c('v-file-input',{staticClass:"d-none",attrs:{"id":"csvImportInput","type":"file","accept":".csv"},model:{value:(_vm.csvFile),callback:function ($$v) {_vm.csvFile=$$v},expression:"csvFile"}}),_c('add-student-dialog',{attrs:{"title":_vm.dialogTitle,"student":_vm.dialogStudent,"slug":_vm.dialogSlug},on:{"save":_vm.getStudents},model:{value:(_vm.isDialogActive),callback:function ($$v) {_vm.isDialogActive=$$v},expression:"isDialogActive"}}),_c('modal',{directives:[{name:"show",rawName:"v-show",value:(_vm.popupMsg !== ''),expression:"popupMsg !== ''"}],on:{"close":function($event){_vm.popupMsg = ''}}},[_vm._v(" "+_vm._s(_vm.popupMsg)+" ")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }