<template>
  <v-card
    :elevation="$vuetify.breakpoint.mobile ? 0 : 2"
    class="my-10 mx-auto"
    :class="{ 'w-60': !$vuetify.breakpoint.mobile }"
  >
    <v-card-title
      v-text="kindergarden.name"
      class="py-8 text-h4 justify-center"
    />
    <div class="py-15 mx-auto d-flex flex-wrap justify-center">
      <div class="px-8 py-3" v-for="obj in iconFields" :key="obj.id">
        <tooltip-icon x-large :color="obj.color" :icon="obj.icon">
          {{ $t(`kindergarden.${obj.fieldName}`) }}
        </tooltip-icon>
      </div>
    </div>
    <div class="mx-2 d-flex align-center flex-wrap flex-lg-row-reverse">
      <v-carousel
        v-if="mediaList.length"
        height="350"
        class="mx-10 my-8 mx-auto rounded-lg carousel"
        show-arrows-on-hover
        hide-delimiter-background
        delimiter-icon="mdi-minus"
      >
        <v-carousel-item
          v-for="media in mediaList"
          :key="media.id"
          reverse-transition="fade-transition"
          transition="fade-transition"
        >
          <iframe
            v-if="media.mediaType === 'video'"
            class="w-100"
            height="350"
            :src="youtubeToEmbeddedUrl(media.videoUrl)"
            frameborder="0"
            allow="autoplay; encrypted-media"
            allowfullscreen
          />
          <img v-else :src="media.imageUrl" class="w-100" height="350" />
        </v-carousel-item>
      </v-carousel>

      <v-card class="mx-auto" :class="{ 'w-35': !$vuetify.breakpoint.mobile }">
        <v-subheader v-text="$t('kindergarden.kindergardenDetails')" />
        <v-list class="mx-auto py-0">
          <template v-for="(value, fieldName, index) in textFields">
            <v-list-item :key="fieldName" hover>
              <v-list-item-action>
                <v-icon>mdi-label</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  <b>{{ $t(`kindergarden.${fieldName}`) }}</b>
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ value !== null ? value : $t("errors.unknown") }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider :key="index" />
          </template>
        </v-list>
      </v-card>
    </div>
    <v-card-actions class="py-6 mx-auto w-fit-content">
      <v-btn
        x-large
        v-text="$t('userActions.back')"
        color="primary"
        text
        @click="$router.push({ name: 'KindergardensExplorer' })"
      />
      <v-btn
        x-large
        v-text="$t('kindergarden.makeContact')"
        color="primary"
        text
        @click="makeContact"
      />
    </v-card-actions>
    <detail-modal
      width="500"
      v-model="isModalOpen"
      :title="$t('kindergarden.contactInfo')"
      :buttonText="$t('userActions.close')"
    >
      <title-to-text
        :title="$t('kindergarden.teacherName')"
        :text="kindergarden.teacherName || $t('errors.unknown')"
      />
      <title-to-text
        :title="$t('kindergarden.email')"
        :text="kindergarden.email || $t('errors.unknown')"
      />
      <title-to-text
        :title="$t('kindergarden.phoneNumber')"
        :text="kindergarden.phoneNumber || $t('errors.unknown')"
      />
    </detail-modal>
  </v-card>
</template>

<script>
import pick from "lodash/pick"
import Utils from "../helpers/utils"
import { SERVER } from "../helpers/constants/constants"
import TooltipIcon from "../components/TooltipIcon"
import DetailModal from "../components/DetailModal"
import TitleToText from "../components/TitleToText"

export default {
  components: {
    TitleToText,
    TooltipIcon,
    DetailModal,
  },
  props: {
    kindergarden: {
      type: Object,
      required: true,
    },
    mediaList: {
      type: Array,
      required: true,
    },
  },
  methods: {
    youtubeToEmbeddedUrl: Utils.youtubeToEmbeddedUrl,
    makeContact() {
      this.isModalOpen = true
    },
  },
  computed: {
    iconFields() {
      const iconFields = []

      const fieldToIcon = {
        isAccessible: "mdi-wheelchair-accessibility",
        isParkingAccessible: "mdi-car",
        isStuffCertified: "mdi-certificate",
        isStuffCprCertified: "mdi-doctor",
        hasFirstAidKit: "mdi-medical-bag",
        isSafetyCompliant: "mdi-seatbelt",
        hasSurveillanceCameras: "mdi-cctv",
        isUnioned: "mdi-office-building-outline",
      }
      const filtered = pick(this.kindergarden, ...Object.keys(fieldToIcon))

      for (const [fieldName, value] of Object.entries(filtered)) {
        const field = { fieldName, icon: fieldToIcon[fieldName] }
        switch (value) {
          case SERVER.yesNoUnknown.yes:
            field.color = "green darken-4"
            break
          case SERVER.yesNoUnknown.no:
            field.color = "red accent-4"
            break
        }
        iconFields.push(field)
      }
      return iconFields
    },

    textFields() {
      // format the text values of the object (e.g., filter, translate, convert to str)
      const textFields = pick(
        this.kindergarden,
        "teacherName",
        "ageGroup",
        "languagesSpoken",
        "shortDescription",
        "address",
        "numberOfChildren",
        "numberOfStaffMembers",
        "childrenPerStuff",
        "seniority"
      )
      textFields.ageGroup = `${this.$tc("kindergarden.ages", 1)} ${Math.min(
        ...textFields.ageGroup
      )}-${Math.max(...textFields.ageGroup)}`

      textFields.languagesSpoken = textFields.languagesSpoken
        .map(lang => this.$t(`languages.${lang}`))
        .join(", ")

      return textFields
    },
  },
  data() {
    return {
      isModalOpen: false,
    }
  },
}
</script>

<style lang="scss" scoped>
.carousel {
  max-width: 650px;
}
</style>
