<template>
  <div>
    <v-row class="pt-10 ml-0">
      <v-col
        cols="4"
        md="3"
        class="right-pane white-bg"
        :class="{ 'pr-10': !$vuetify.breakpoint.mobile }"
      >
        <pagination-checkbox-group
          v-for="filter in PROGRAMS_CHECKBOX_FILTERS"
          class="checkbox-group"
          :class="{ 'checkbox-small': $vuetify.breakpoint.mobile }"
          :key="filter.id"
          :name="filter.name"
          :title="filter.readableName"
          :items="filter.options"
        />
      </v-col>
      <v-col
        cols="8"
        md="9"
        :class="{ 'px-10': !$vuetify.breakpoint.mobile }"
      >
        <h1 v-text="$t('kindergarden.kindergardensExplorer')" class="pb-6" />
        <h3 v-text="$t('kindergarden.explorerAndFindKindergardensThatSuitYou')" />
        <pagination-search-bar class="search-bar mx-auto pt-16" />
        <div class="text-center pt-10 overline">
          {{ totalKindergardens }} {{ $t("kindergarden.kindergardensFound") }}
        </div>
        <v-row
          dense
          justify="space-between"
          class="cards-wrapper mx-auto py-10"
        >
          <v-col
            cols="12"
            sm="6"
            lg="5"
            class="py-10"
            v-for="kindergarden in kindergardensList"
            :key="kindergarden.id"
          >
            <info-card
              hide-star
              v-model="kindergarden.isOrdered"
              :imgUrl="KINDERGARDEN_MEDIA_PLACEHOLDER"
              :title="kindergarden.name"
              @click="openKindergarden(kindergarden.slug)"
            >
              <title-to-text :title="$t('general.address')" :text="kindergarden.address || $t('errors.unknown')" />
            </info-card>

          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <!-- <router-view v-model="isKindergardenOpen" /> -->
    <end-of-page-detector @endOfPage="onEndOfPage" />
  </div>
</template>

<script>
import InfoCard from "../components/InfoCard"
import TitleToText from "../components/TitleToText"
import PaginationCheckboxGroup from "../components/PaginationCheckboxGroup"
import PaginationSearchBar from "../components/PaginationSearchBar"
import EndOfPageDetector from "../components/EndOfPageDetector"
import {
  PROGRAMS_CHECKBOX_FILTERS,
  SERVER,
} from "../helpers/constants/constants"
import { KINDERGARDEN_MEDIA_PLACEHOLDER } from "../helpers/constants/images"
import { mapActions, mapGetters, mapState } from "vuex"

export default {
  components: {
    InfoCard,
    TitleToText,
    PaginationCheckboxGroup,
    PaginationSearchBar,
    EndOfPageDetector,
  },

  computed: {
    ...mapState("kindergarden", ["kindergardensList", "totalKindergardens"]),
    ...mapGetters("school", ["schoolSlug"]),
  },

  methods: {
    ...mapActions("pagination", ["incrementPage", "updatePagination"]),
    ...mapActions("kindergarden", [
      "getKindergardensList",
      "createKindergardenOrder",
      "cancelKindergardenOrder",
      "reCreateKindergardenOrder",
    ]),

    onEndOfPage() {
      // trigger kindergardens load on end of page
      this.recentlyScrolled = true
      this.incrementPage()
    },

    openKindergarden(slug) {
      this.isKindergardenOpen = true
      this.$router.push({ name: "KindergardenDetailed", params: { slug } })
    },

    async getKindergardens() {
      if (this.recentlyScrolled) {
        this.recentlyScrolled = false
        if (this.totalKindergardens > this.kindergardensList.length) {
          // add new kindergardens if there are items left to fetch. do not override.
          this.getKindergardensList(false)
        }
      } else {
        // fetch & ovrride kindergardens list
        this.updatePagination({ page: 1 })
        this.getKindergardensList(true)
      }
    },

    getCardSubtitle(orderStatus) {
      const prefix = this.$t("general.status")
      let status = this.$t("kindergarden.available")
      if (orderStatus) {
        status = this.$t(`kindergarden.${orderStatus}`)
      }
      return `${prefix}: ${status}`
    },

    onStarChange(kindergarden, isStarred) {
      // (dis)request a kindergarden and change order status accordingly
      try {
        if (isStarred) {
          this.requestKindergarden(kindergarden)
        } else {
          this.disRequestKindergarden(kindergarden)
        }
      } catch (err) {
        // add toast
        return
      }
    },

    requestKindergarden(kindergarden) {
      if (kindergarden.orderStatus === SERVER.kindergardenOrderStatus.cancelled) {
        return this.reCreateKindergardenOrder({
          schoolSlug: this.schoolSlug,
          kindergardenSlug: kindergarden.slug,
        })
      }
      return this.createKindergardenOrder({
        schoolSlug: this.schoolSlug,
        kindergardenSlug: kindergarden.slug,
      })
    },

    disRequestKindergarden(kindergarden) {
      return this.cancelKindergardenOrder({
        schoolSlug: this.schoolSlug,
        kindergardenSlug: kindergarden.slug,
      })
    },
  },

  data() {
    return {
      KINDERGARDEN_MEDIA_PLACEHOLDER,
      PROGRAMS_CHECKBOX_FILTERS,
      recentlyScrolled: false,
      isKindergardenOpen: true,
    }
  },

  watch: {
    "$store.state.pagination": {
      // re-fetch if pagination changed
      deep: true,
      handler() {
        this.getKindergardens()
      },
    },
    isKindergardenOpen(value) {
      // route back on close
      if (!value) {
        this.$router.push({ name: "KindergardensExplorer" })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.cards-wrapper {
  max-width: 1200px;
}
.right-pane {
  border-left: $light-grey 1px solid;
}
.checkbox-group {
  float: right;
  width: 100%;
}
.checkbox-small::v-deep label {
  font-size: 12px;
}
.search-bar {
  max-width: 450px;
}
</style>
