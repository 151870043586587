import Vue from "vue"
import Vuetify from "vuetify/lib/framework"

Vue.use(Vuetify)

export default new Vuetify({
  rtl: true,
  theme: {
    themes: {
      light: {
        primary: "#a42866",
        secondary: "#292929",
        "secondary-lighten-1": "#2d2c2c",
        "secondary-lighten-2": "#363535",
      },
    },
  },
})
