import axios from "axios"
import {
  GET_KINDERGARDEN_LIST_API_URL,
  GET_KINDERGARDEN_MEDIA_LIST_API_URL,
} from "../helpers/constants/constants"

const kindergarden = {
  getKindergarden(slug) {
    return axios.get(`${GET_KINDERGARDEN_LIST_API_URL}${slug}/`)
  },
  getKindergardenMediaList(kindergardenSlug) {
    return axios.get(GET_KINDERGARDEN_MEDIA_LIST_API_URL, {
      params: {
        kindergarden__slug: kindergardenSlug,
      },
    })
  },
  getKindergardensList(params) {
    // :Object params: query params
    return axios.get(GET_KINDERGARDEN_LIST_API_URL, { params })
  },
}

export default kindergarden
