<template>
  <v-row no-gutters>
    <v-col class="secondary-lighten-2 relative" cols="12" lg="5">
      <router-view />
    </v-col>
    <v-col class="relative d-none d-lg-block overflow-hidden" lg="7">
      <div class="w-100" :style="style" />
    </v-col>
  </v-row>
</template>
<script>
import { BACKGROUNDS } from "../helpers/constants/images"
export default {
  data() {
    return {
      style: {
        backgroundImage: `url(${BACKGROUNDS.welcome})`,
        color: "red",
        height: "100%",
        backgroundSize: "cover"
      },
    }
  },
}
</script>
