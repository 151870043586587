import Api from "../../api"

function getDefaultState() {
  return {
    kindergardensList: [],
    totalKindergardens: null,
  }
}

const kindergarden = {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    FLUSH_STATE(state) {
      Object.assign(state, getDefaultState())
    },
    ADD_KINDERGARDENS_TO_LIST(state, kindergardensList) {
      state.kindergardensList.push(...kindergardensList)
    },
    SET_KINDERGARDEN_LIST(state, kindergardensList) {
      state.kindergardensList = kindergardensList
    },
    SET_KINDERGARDENS_TOTAL(state, total) {
      state.totalKindergardens = total
    },
  },
  actions: {
    flushState({ commit }) {
      commit("FLUSH_STATE")
    },
    async getKindergarden(ctx, slug) {
      // fetch and return a specific slug. does not save to store.
      let res = await Api.kindergarden.getKindergarden(slug)
      return res.data
    },
    async getKindergardenMediaList(ctx, slug) {
      // fetch and return a specific kindergarden's media. does not save to store.
      let res = await Api.kindergarden.getKindergardenMediaList(slug)
      return res.data.results
    },
    async getKindergardensList({ commit, state, rootGetters }, override = true) {
      // :boolean override: whether to override the kindergardens list or not (i.e., extend)
      const params = rootGetters["pagination/apiParams"]
      const mutation = override ? "SET_KINDERGARDEN_LIST" : "ADD_KINDERGARDENS_TO_LIST"
      let res = await Api.kindergarden.getKindergardensList(params)
      commit(mutation, res.data.results)
      commit("SET_KINDERGARDENS_TOTAL", res.data.count)
      return state.kindergardensList
    },
  },
}

export default kindergarden
