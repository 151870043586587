import i18n from "../../plugins/i18n"

const SERVER_URL = process.env.VUE_APP_BACKEND_URL
export const LOGIN_API_URL = `${SERVER_URL}/auth/login/`
export const RESET_PASSWORD_URL = `${SERVER_URL}/auth/password-reset/confirm/`
export const UPDATE_COORDINATOR_PROFILE_API_URL = `${SERVER_URL}/coordinators_profiles/`
export const UPDATE_CONSUMER_PROFILE_API_URL = `${SERVER_URL}/consumers_profiles/`
export const UPDATE_USER_API_URL = `${SERVER_URL}/users/`
export const GET_CONSUMER_PROFILE_API_URL = `${SERVER_URL}/consumers_profiles/me/`
export const GET_COORDINATOR_PROFILE_API_URL = `${SERVER_URL}/coordinators_profiles/me/`
export const GET_USER_DETAILS_API_URL = `${SERVER_URL}/users/me/`
export const GET_SCHOOL_DETAILS_API_URL = `${SERVER_URL}/schools/me`
export const UPDATE_SCHOOL_DETAILS_API_URL = `${SERVER_URL}/schools/`
export const GET_SCHOOL_STUDENTS_LIST_API_URL = `${SERVER_URL}/manage_consumers/`
export const ADD_SCHOOL_STUDENTS_API_URL = `${SERVER_URL}/manage_consumers/`
export const EDIT_SCHOOL_STUDENTS_API_URL = `${SERVER_URL}/manage_consumers/`
export const DELETE_SCHOOL_STUDENTS_API_URL = `${SERVER_URL}/manage_consumers/`
export const GET_PROGRAM_LIST_API_URL = `${SERVER_URL}/activities/`
export const GET_PROGRAM_MEDIA_LIST_API_URL = `${SERVER_URL}/activity_media/`
export const ORDER_SCHOOL_PROGRAM_API_URL = `${SERVER_URL}/manage_school_activity/`
export const GET_CONSUMER_PROGRAM_LIST_API_URL = `${SERVER_URL}/consumer_activities/`
export const CONSUMER_JOIN_PROGRAM_API_URL = `${SERVER_URL}/consumer_activities/`
export const CONSUMER_LEAVE_PROGRAM_API_URL = `${SERVER_URL}/consumer_activities/`
export const GET_KINDERGARDEN_LIST_API_URL = `${SERVER_URL}/kindergardens/`
export const GET_KINDERGARDEN_MEDIA_LIST_API_URL = `${SERVER_URL}/kindergarden_media/`

export const TOKEN_COOKIE_NAME = "token"
export const SCHOOL_GRADES_ITEMS = [
  { value: 1, text: i18n.t("grades.1") },
  { value: 2, text: i18n.t("grades.2") },
  { value: 3, text: i18n.t("grades.3") },
  { value: 4, text: i18n.t("grades.4") },
  { value: 5, text: i18n.t("grades.5") },
  { value: 6, text: i18n.t("grades.6") },
  { value: 7, text: i18n.t("grades.7") },
  { value: 8, text: i18n.t("grades.8") },
  { value: 9, text: i18n.t("grades.9") },
  { value: 10, text: i18n.t("grades.10") },
  { value: 11, text: i18n.t("grades.11") },
  { value: 12, text: i18n.t("grades.12") },
]

export const PASSWORD_REGEX_PATTERN =
  "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})"
export const ISRAELI_PHONE_REGEX_PATTERN =
  "^0(([23489]{1}\\d{7})|[5]{1}\\d{8})$"
export const EMAIL_REGEX_PATTERN =
  '^(([^<>()[\\]\\.,;:\\s@\\"]+(\\.[^<>()[\\]\\.,;:\\s@\\"]+)*)|(\\".+\\"))@(([^<>()[\\]\\.,;:\\s@\\"]+\\.)+[^<>()[\\]\\.,;:\\s@\\"]{2,})$'
export const WEBSITE_REGEX_PATTERN =
  /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?/gi
export const YOUTUBE_ID_REGEX_PATTERN =
  /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
export const ZIP_CODE_VALIDATION_RULE = "required|numeric|digits:7"
export const PROGRAMS_CHECKBOX_FILTERS = [
  {
    name: "space",
    readableName: i18n.t("programFilters.space"),
    options: [
      {
        label: i18n.t("programFilters.blueCore"),
        value: "blue",
      },
      {
        label: i18n.t("programFilters.greenEmpowermentEnrichment"),
        value: "green",
      },
      {
        label: i18n.t("programFilters.orangeCommunity"),
        value: "orange",
      },
    ],
  },
  {
    name: "domainOfActivity",
    readableName: i18n.t("programFilters.domainOfActivity"),
    options: [
      {
        label: i18n.t("programFilters.scienceAndTech"),
        value: "scienceAndTech",
      },
      {
        label: i18n.t("programFilters.extremeSports"),
        value: "extremeSports",
      },
      {
        label: i18n.t("programFilters.field"),
        value: "field",
      },
    ],
  },
  {
    name: "natureOfActivity",
    readableName: i18n.t("programFilters.natureOfActivity"),
    options: [
      {
        label: i18n.t("programFilters.individual"),
        value: "individual",
      },
      {
        label: i18n.t("programFilters.cooperative"),
        value: "cooperative",
      },
      {
        label: i18n.t("programFilters.hybrid"),
        value: "hybrid",
      },
    ],
  },
  {
    name: "targetAudience",
    readableName: i18n.t("programFilters.targetAudience"),
    options: [
      {
        label: i18n.t("programFilters.allAges"),
        value: "0",
      },
      {
        label: i18n.t("grades.1"),
        value: "1",
      },
      {
        label: i18n.t("grades.2"),
        value: "2",
      },
      {
        label: i18n.t("grades.3"),
        value: "3",
      },
      {
        label: i18n.t("grades.4"),
        value: "4",
      },
      {
        label: i18n.t("grades.5"),
        value: "5",
      },
      {
        label: i18n.t("grades.6"),
        value: "6",
      },
      {
        label: i18n.t("grades.7"),
        value: "7",
      },
      {
        label: i18n.t("grades.8"),
        value: "8",
      },
      {
        label: i18n.t("grades.9"),
        value: "9",
      },
      {
        label: i18n.t("grades.10"),
        value: "10",
      },
      {
        label: i18n.t("grades.11"),
        value: "11",
      },
      {
        label: i18n.t("grades.12"),
        value: "12",
      },
    ],
  },
]

export const SERVER = {
  userTypes: {
    coordinator: "COORDINATOR", // i.e., principals
    consumer: "CONSUMER", // i.e., students
    vendor: "VENDOR", // i.e., organization managers
  },
  programOrderStatus: {
    cancelled: "CANCELLED",
    pendingAdminApproval: "PENDING_ADMIN_APPROVAL",
    approved: "APPROVED",
  },
  yesNoUnknown: {
    yes: "YES",
    no: "NO",
    unknown: "UNKNOWN",
  },
}

export const YOUTUBE_EMBED_URL = "https://www.youtube.com/embed/"
