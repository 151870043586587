var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","max-width":"360px"},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline pt-13 justify-center",domProps:{"textContent":_vm._s(_vm.title)}}),_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{staticClass:"form mx-auto",on:{"submit":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}}},[_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"mt-8",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('general.name'),"error-messages":errors},model:{value:(_vm.formInput.name),callback:function ($$v) {_vm.$set(_vm.formInput, "name", $$v)},expression:"formInput.name"}})]}}],null,true)})],1),_c('v-col',{staticClass:"mt-8",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('general.email'),"error-messages":errors},model:{value:(_vm.formInput.email),callback:function ($$v) {_vm.$set(_vm.formInput, "email", $$v)},expression:"formInput.email"}})]}}],null,true)})],1),_c('v-col',{staticClass:"mt-8",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"gender","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":_vm.$t('gender.gender'),"items":_vm.genderChoices,"error-messages":errors},model:{value:(_vm.formInput.profile.gender),callback:function ($$v) {_vm.$set(_vm.formInput.profile, "gender", $$v)},expression:"formInput.profile.gender"}})]}}],null,true)})],1)],1)],1),_c('v-card-actions',{staticClass:"pb-5 mt-12"},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":"","type":"button"},on:{"click":_vm.close}},[_vm._v(" "+_vm._s(_vm.$t("userActions.close"))+" ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":"","type":"submit","disabled":invalid}},[_vm._v(" "+_vm._s(_vm.$t("userActions.save"))+" ")])],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }