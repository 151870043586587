import school from "./school"
import user from "./user"
import auth from "./auth"
import consumer from "./consumer"
import coordinator from "./coordinator"
import config from "./config"
import program from "./program"
import consumerProgram from "./consumerProgram"
import kindergarden from "./kindergarden"

export default {
  school,
  program,
  user,
  consumer,
  coordinator,
  auth,
  config,
  consumerProgram,
  kindergarden,
}
